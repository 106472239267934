import VideoCard from '../components/video-card.js'

export default function videoCardMapper(props){
    const mappedData = props.data.map(
        (vid, idx) => (
            <VideoCard
                key={vid.url}
                index={idx}
                title={vid.title}
                url={vid.url}
                category={props.category}
            />
        )
    )

    return mappedData
}

import { Col } from 'react-bootstrap'

export default function Playlists(props){
	const pl = [
		{
			label: 'Cutting Things! Tatami, Bottles, etc',
			link: 'https://www.youtube.com/watch?v=oH906BWLeCs&list=PLarEh8Yu-h7Gw45rqHLFAw-BsR2Mlja96&index=1&ab_channel=SamuraiJames',
		},
		{
			label: 'Medieval Weapons (Knight)',
			link: 'https://www.youtube.com/watch?v=dEA_KQGc7k0&list=PLarEh8Yu-h7G43EvpaXS-jZ5JiGPQlpKj&index=1&ab_channel=SamuraiJames',
		},
		{
			label: 'Medieval Armor (Knight)',
			link: 'https://www.youtube.com/watch?v=dEA_KQGc7k0&list=PLarEh8Yu-h7ENd2J75z0se-jL7k2hK32I&index=1&ab_channel=SamuraiJames',
		},
		{
			label: 'Samurai Weapons (Japanese)',
			link: 'https://www.youtube.com/watch?v=oH906BWLeCs&list=PLarEh8Yu-h7GND_e5ELubm9tlL9cVoQ1M&index=1&ab_channel=SamuraiJames',
		},
		{
			label: 'Samurai Armor (Japanese)',
			link: 'https://www.youtube.com/watch?v=2UESBs9QmeY&list=PLarEh8Yu-h7EY4w3I3td69o20TKp5vO_6&index=1&ab_channel=SamuraiJames',
		},
		{
			label: 'TV, Movies, and Anime',
			link: 'https://www.youtube.com/watch?v=Ql5LGQ2cSAc&list=PLarEh8Yu-h7FoRJIWG6FldUdNfA5MGUrU&index=1&ab_channel=SamuraiJames',
		},
		{
			label: 'Ronin Warriors',
			link: 'https://www.youtube.com/watch?v=TBuS0snvONs&list=PLarEh8Yu-h7EsNc58FUKf8Eawy1jrq31j&index=1&ab_channel=SamuraiJames',
		},
		{
			label: 'Game Reviews & Gameplay Recordings',
			link: 'https://www.youtube.com/watch?v=ZYAVrL1fPtU&list=PLarEh8Yu-h7ENsu_OPJZkq8Jzr0R0PYZh&index=1&ab_channel=SamuraiJames',
		},
	]
	const mapped = pl.map(
		item => (
			<li key={item.link}>
				<a href={item.link} target="_blank" rel="noreferrer">
					{item.label}
				</a>
			</li>
		)
	)

	return (
      <Col>
        <h2>Playlists</h2>

		<p>
			Links to the YouTube playlists on my channel
		</p>

		<ul>
			{mapped}
		</ul>
      </Col>
    )
  }
  
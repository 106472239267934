import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'

import data from '../data/videos.js'

import './taglist.css'

export default function Taglist(props){
    const [params, setParams] = useSearchParams()
    const activeTag = params.get("tag")
    const mappedTags = _.sortBy(
        _.uniq(
            _.flatten(
                _.map(
                    data, x => x.tags
                )
            )
        )
    ).map(
        tag => {
            const obj = {}
            const cnt = _.countBy(
                data, item => item.tags.indexOf(tag) >= 0
            )

            if (tag.toLowerCase() === activeTag){
                obj.active = true
            }

            return (
                <div key={tag}>
                    <Button
                        onClick={ () => setParams({ tag })}
                        variant="link"
                        {...obj}
                    >
                        {tag} ({ _.has(cnt, 'true')
                            ? cnt.true
                            : 0}
                        )
                    </Button>
                </div >
            )
        }
    )

    return (
        <div id="videoTagList">
            <h4>Tags</h4>
            {mappedTags}
        </div>
    )
}

import MenuButton from './menuButton.js'

export default function Menu(props){
    return (
        <div className="main-menu">
            <MenuButton {...props} label="Home" category="home" />
            <MenuButton {...props} label="About" category="about" />
            <MenuButton {...props} label="Videos" category="videos" />
            <MenuButton {...props} label="Playlists" category="playlists" />
            <MenuButton {...props} label="Contact" category="contact" />
        </div>
    )
}

import { Container, Row, Col } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'

import data from '../data/videos.js'
import VideoCardMapper from '../components/video-card-mapper.js'
import TagList from '../components/taglist.js'

export default function Videos(props){
    const [params] = useSearchParams()
    const activeTag = params.get("tag")
    const hasTag = activeTag !== null
    const filteredData = (hasTag)
        ? _.filter(
            data,
            item => item.tags.indexOf(activeTag) >= 0
        )
        : data

    return (
        <>
            <Col>
                <h2>
                    Videos
                    {
                        hasTag &&

                        <>
                            {` - ${activeTag} `}
                        </>
                    }
                </h2>
                <Container>
                    <Row>
                        {
                            filteredData.length === 0 &&
                            
                            <Col>
                                {'No videos in this tag'}
                            </Col>
                        }
                        {
                            filteredData.length > 0 &&

                            <VideoCardMapper data={filteredData} />
                        }
                    </Row>
                </Container>
            </Col>
            <Col sm={3}>
                <TagList />
            </Col>
        </>
    )
}

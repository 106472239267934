import { Col } from 'react-bootstrap'

export default function Home(props){
  return (
    <Col>
      <h2>Home</h2>
      <p>
        I am a history enthusiast with a particular focus on knights and samurai, especially their weapons and armor.
      </p>
      <p>
        I have a
        {' '}
        <a rel="noreferrer" href="https://www.youtube.com/channel/UCokjCJubDvtvXboeTJ-xwsA" target="_blank">YouTube channel</a>
        {' '}
        that I post videos on all of these topics. The menu buttons are category groups of those videos and will open a direct link to that video on YouTube. If these topics interest you, please visit it and subscribe if you enjoy the content.
      </p>
      <p>
        <strong>
          If you want to support my YouTube channel:
        </strong>
      </p>
      <ul>
        <li>
          Use my
          {' '}
          <a rel="noreferrer" href="https://shop.samurai-armor.com/?link=9ROS9BD" target="_blank">Iron Mountain Armory affiliate link</a>
          {' '}
          to make purchases from them. Like other affiliate programs, there is no additional cost to you, but I get a portion of the sale.
        </li>
        <li>
          Become a patron on my
          {' '}
          <a rel="noreferrer" href="https://www.patreon.com/samuraijames" target="_blank">patreon page</a>
          {' '}
        </li>
        <li>
          Make a donation on
          {' '}
          <a rel="noreferrer" href="https://ko-fi.com/samuraijames" target="_blank">my ko-fi page</a>
        </li>
      </ul>
      <p>
        <em>
          Everything I get for the channel goes towards the cost of running the channel: audio / video equipment, tatami mats and pool noodles for sword reviews / cutting, if I get enough to cover all of those costs then I will invest it into items to review for the channel. I may even open that up to an audience choice between a few pre-selected items to see what the most popular request is for.
        </em>
      </p>
    </Col>
  )
}

import React from "react";
import { Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'

import Menu from './components/menu.js'


export default function App(){
  return (
    <div className="App">
      <div className="content-wrapper">
        <div className="banner">
          <h1>
            SamuraiJames<small>.com</small>
            <span>
              samurai.james.1603(at)gmail(dot)com
            </span>
          </h1>
        </div>

        <div className="header">
          <img src="img/banner.png" className="img-fluid" alt="Site banner" />
        </div>

        <Menu />

        <div className="content">
          <Container fluid={true}>
            <Row>
              <Outlet />
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from 'react-router-dom'

export default class MenuButton extends React.Component{
    constructor(props){
        super(props)

        this.handleClick = this.handleClick.bind(this)
    }

    render(){
        const activeClass = (this.props.category === this.props.activeCategory)
            ? ' active'
            : ''

        return (
            <Link to={`/${this.props.category}`}
                className={`btn btn-primary ${activeClass}`}
                onClick={this.handleClick}
            >
                {this.props.label}
            </Link>
        )
    }

    handleClick(){
        this.props.handleCategoryChange(this.props.category)
    }
}

import { Col } from 'react-bootstrap'

export default function About(props){
  return (
    <Col>
      <h2>About Me</h2>
      <p>
        In high school I bought my first two swords, a "ninja" sword (ninjato) and a katana. I also spent a couple years studying Shorin Ryu karate, an Okinawan martial art from Japan, and one of the oldest forms. The majority of what I did was unarmed, with a little bit of nunchaku.
      </p>
      <p>
        A few years after the karate school closed, I bought my first suit of combat capable medieval plate armor and did a year of SCA heavy combat (rattan). Through the host, I learned how to maintain armor: removing rust, replacing rivets, and making / replacing straps and buckles.
      </p>
      <p>
        After that year, the host was unable to continue providing space for us. As such, my experience shifted to working on my armor a bit and doing some cutting in the back yard for the next few years. I have been cutting for over 15 years, taking formal classes around 5 years at events and as part of the HEMA class rotation.
      </p>
      <p>
        In 2010 I began HEMA (Historical European Martial Arts) when I took my first (and only) class with John Clements. This was a matter of convenience since he was doing a seminar an hour away from me. While there is a lot of criticism for him, he was a good teacher for that class and cleared up a lot of the fantasies of knights and samurai. There is no "mystical force" in Japanese martial arts, it is all a matter of force, leverage, and physics. European martial arts is just as refined, with masters and schools; it is not slow or clunky.
      </p>
      <p>
        I have hands-on experience with actual antique medieval armor, and it gave me a new perspective on the modern replicas that could not be gleaned through books or videos. One day I hope to get hands on with some antique samurai armor too.
      </p>
      <p>
        I have been a "gamer" (video game player) since the ancient days of Atari and Intellivision, so I also do reviews on medieval or Japanese themed games. Movies and TV show reviews from time to time as well. I don't spend much time playing games or watching TV / Movies any more.
      </p>
      <p>
        I am currently a sporadic HEMA student, however there are no eastern martial arts near me for swordsmanship. As such, I am self taught on that through books, videos, and hands-on activities.
      </p>
    </Col>
  )
}

export default function VideoCard(props){
    const imgSplit = props.url.split('/')
    const imgName = imgSplit[imgSplit.length - 1]

    return (
        <div className="col-sm-12 col-md-6 col-lg-4 d-flex align-items-stretch">
            <div className="card">
                <a
                    rel="noreferrer"
                    target="_blank"
                    href={props.url}
                >
                    <img
                        alt="video thumbnail"
                        className="card-img-top"
                        src={`/img/videos/thumbs/${imgName}.jpg`}
                    />
                </a>
                <div className="card-body">
                    <div className="card-text">
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={props.url}
                        >
                            {props.title}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

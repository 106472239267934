const videoList = [
    {
        title: 'Unboxing: Iron Mountain Armory items',
        url: 'https://youtu.be/oN5O5vPV4MA',
        tags: ['Samurai','Armor'],
    },
    {
        title: 'Unboxing Customized Samurai Swords : Nodachi, Katana, & Wakizashi (by KatanasForSale)',
        url: 'https://youtu.be/0D48e8Ze56I',
        tags: ['Samurai','Swords','Weapons'],
    },
    {
        title: 'Customized Samurai Armor: Iron Mountain Armory "Taisho" class odoshi scale gusoku',
        url: 'https://youtu.be/1EpJezj-kQw',
        tags: ['Samurai','Armor'],
    },
    {
        title: 'Iron Mountain Armory Taisho armor review, 8 months of ownership (Custom Samurai Armor)',
        url: 'https://youtu.be/RjAXR7P82U0',
        tags: ['Samurai','Armor','Reviews'],
    },
    {
        title: 'Cutting a tatami mat with a katana while wearing samurai armor! (Aug 2020)',
        url: 'https://youtu.be/-BJRiHkSv14',
        tags: ['Samurai','Armor','Cutting','Swords','Weapons'],
    },
    {
        title: 'Customized Katana - 1 year later',
        url: 'https://youtu.be/8OvT2XFVSYY',
        tags: ['Samurai','Swords','Weapons'],
    },
    {
        title: 'Customized 9260 Nodachi: Samurai Sword Store review (With cutting!)',
        url: 'https://youtu.be/xr7zdL0BPUo',
        tags: ['Samurai','Swords','Reviews','Weapons'],
    },
    {
        title: 'Custom 9260 Nodachi: Follow up',
        url: 'https://youtu.be/0IWLCeRAKKo',
        tags: ['Samurai','Swords','Weapons'],
    },
    {
        title: 'Game Review: Sekiro',
        url: 'https://youtu.be/TWfD_z2sg1E',
        tags: ['Games','Reviews'],
    },
    {
        title: 'Game Review: Nioh',
        url: 'https://youtu.be/--KR423gNmk',
        tags: ['Games','Reviews'],
    },
    {
        title: 'Game Review: Ghost of Tsushima',
        url: 'https://youtu.be/sj-6XuCIMBQ',
        tags: ['Games','Reviews'],
    },
    {
        title: 'Slicing and Styling in snow (samurai & snow... snomurai?)',
        url: 'https://youtu.be/_jy6iuibYEk',
        tags: ['Samurai','Armor','Swords','Polearms','Weapons'],
    },
    {
        title: 'How do you display samurai armor?',
        url: 'https://youtu.be/8OGI0TAU0Wc',
        tags: ['Samurai','Armor','Tutorials'],
    },
    {
        title: 'Samurai Swords: Blade up? Blade down?',
        url: 'https://youtu.be/jk__a6ncXPw',
        tags: ['Samurai','Swords','Tutorials','Weapons'],
    },
    {
        title: 'Age of Samurai: Battle for Japan review (Netflix docuseries)',
        url: 'https://youtu.be/Ql5LGQ2cSAc',
        tags: ['TV','Reviews'],
    },
    {
        title: 'Who is Samurai James? My first sword, first armor, martial arts background, and more',
        url: 'https://youtu.be/bWUT543OkFk',
        tags: [],
    },
    {
        title: 'Wanna buy a sword? Here\'s a guide to buying your first - or next - sword!',
        url: 'https://youtu.be/DO1YrepG1tk',
        tags: ['Tutorials','Swords','Weapons'],
    },
    {
        title: 'Iron Mountain Armory: What\'s in the box?',
        url: 'https://youtu.be/Yat1tcJIPt0',
        tags: ['Samurai','Armor'],
    },
    {
        title: 'Unboxing & Initial Review: IceFalcon 6mm stainless haubergeon (chain mail)',
        url: 'https://youtu.be/tvhKTeTxeBc',
        tags: ['Armor','Knight'],
    },
    {
        title: 'How do you pack samurai armor? (Yoroi bitsu)',
        url: 'https://youtu.be/kY50A3OLbKg',
        tags: ['Samurai','Armor','Tutorials'],
    },
    {
        title: 'How do you disassemble a Japanese sword? (Tanto, Wakizashi, Katana, Nodachi, Tachi)',
        url: 'https://youtu.be/RawJC-7XPcg',
        tags: ['Samurai','Swords','Tutorials','Weapons'],
    },
    {
        title: 'How accurate is the armor in Ghost of Tsushima? Plus Iki Island expansion announcement',
        url: 'https://youtu.be/ZYAVrL1fPtU',
        tags: ['Samurai','Armor','Games'],
    },
    {
        title: 'HEMA: What Is It, What Isn\'t It (Plus ACL, BOTN, SCA, LARP, & reply to Shadiversity)',
        url: 'https://youtu.be/OfaFUHy2lm8',
        tags: ['Knight','HEMA','Video Reply'],
    },
    {
        title: 'Samurai plays Ghost of Tsushima: Iki Island in armor (Part 1)',
        url: 'https://youtu.be/j6HIqcG5t7c',
        tags: ['Games'],
    },
    {
        title: 'Samurai plays Ghost of Tsushima: Iki Island in armor (Part 2)',
        url: 'https://youtu.be/vmSI9Y6mrAU',
        tags: ['Games'],
    },
    {
        title: 'Can you cut tatami in a full suit of medieval armor? (Mid-15th Century Milanese style armor)',
        url: 'https://youtu.be/dEA_KQGc7k0',
        tags: ['Knight','Armor','Cutting','Weapons'],
    },
    {
        title: 'How do you tie a kabuto? (Japanese / Samurai Helmet)',
        url: 'https://youtu.be/2UESBs9QmeY',
        tags: ['Samurai','Armor','Tutorials'],
    },
    {
        title: 'Goza Mat Challenge! A&A Bannockburn, how many cuts can I get? #shorts',
        url: 'https://youtu.be/VkSOkqGBGcQ',
        tags: ['Swords','Knight','HEMA','Cutting','Video Reply','Weapons'],
    },
    {
        title: 'Goza Mat Challenge! SamuraiSwordStore customized 9260 Nodachi, how many cuts can I get? #shorts',
        url: 'https://youtu.be/oH906BWLeCs',
        tags: ['Swords','Samurai','Cutting','Video Reply','Weapons'],
    },
    {
        title: 'Want a free sword? Giving one away at 1,000 subscribers! #shorts',
        url: 'https://youtu.be/jXCyk0l7a_A',
        tags: ['Giveaways','Swords','Weapons'],
    },
    {
        title: 'Cutting: Arms & Armor Bannockburn, beach mat #1 (2015)',
        url: 'https://youtu.be/cY2jaW4rA_M',
        tags: ['Cutting','Swords','Knight','Weapons'],
    },
    {
        title: 'Cutting: Arms & Armor Bannockburn, beach mat #2 (2015)',
        url: 'https://youtu.be/wd5gV_ucY2w',
        tags: ['Cutting','Swords','Knight','Weapons'],
    },
    {
        title: 'Cutting: Arms & Armor Bannockburn, beach mat #3 (2015)',
        url: 'https://youtu.be/B9yTnoTb_dY',
        tags: ['Cutting','Swords','Knight','Weapons'],
    },
    {
        title: 'Cutting: Arms & Armor Bannockburn, beach mat #4 (2015)',
        url: 'https://youtu.be/OqpkZLqSrwI',
        tags: ['Cutting','Swords','Knight','Weapons'],
    },
    {
        title: 'Cutting: Arms & Armor Bannockburn, beach mat #5 (2015)',
        url: 'https://youtu.be/lwIecoPmS60',
        tags: ['Cutting','Swords','Knight','Weapons'],
    },
    {
        title: 'Cutting: Albion Talhoffer, beach mat (2015)',
        url: 'https://youtu.be/EKbuIMeLBsM',
        tags: ['Cutting','Swords','Knight','Weapons'],
    },
    {
        title: 'Cutting tatami: Arms & Armor bardiche (type of pole arm) (2015)',
        url: 'https://youtu.be/igzyaPzJpFw',
        tags: ['Cutting','Polearms','Knight','Weapons'],
    },
    {
        title: 'Unboxing and initial impressions: Customized nagamaki and tanto from KatanasForSale',
        url: 'https://youtu.be/6L18k_k3l3c',
        tags: ['Samurai','Swords','Reviews','Weapons'],
    },
    {
        title: 'PMAA Fall Brawl  - Round 1 (2015)',
        url: 'https://youtu.be/JKh1-UZGeGY',
        tags: ['HEMA','Knight','Swords','Cutting','Weapons'],
    },
    {
        title: 'PMAA Fall Brawl  - Round 2 (2015)',
        url: 'https://youtu.be/42iDg362pSU',
        tags: ['HEMA','Knight','Swords','Cutting','Weapons'],
    },
    {
        title: '10 Questions for Katana People (Eric Husayn)',
        url: 'https://youtu.be/PbvlJ1Od_r4',
        tags: ['Swords'],
    },
    {
        title: 'Ironman vs Captain America, Medieval Style: Who will win?',
        url: 'https://youtu.be/0pNY9odXUBs',
        tags: ['Knight','Armor','HEMA'],
    },
    {
        title: 'Tanegashima Teppo: Unboxing and fixing barrel alignment',
        url: 'https://youtu.be/acNlxGpzpxk',
        tags: ['Samurai','Weapons','Reviews','Tutorials','Weapons'],
    },
    {
        title: 'Ronin Warriors - Overview',
        url: 'https://youtu.be/TBuS0snvONs',
        tags: ['Anime','Reviews'],
    },
    {
        title: 'Ronin Warriors - Analysis of the Heroes',
        url: 'https://youtu.be/f-YZz_eoqX8',
        tags: ['Anime','Reviews'],
    },
    {
        title: 'Ronin Warriors - Analysis of the Villains',
        url: 'https://youtu.be/854Z61AFrcI',
        tags: ['Anime','Reviews'],
    },
    {
        title: 'Ronin Warriors - Episode Events Analysis',
        url: 'https://youtu.be/ibONoGq3fBo',
        tags: ['Anime','Reviews'],
    },
    {
        title: 'Interview with StoneBuiltKnives, and a look at a bunch of his custom made knives',
        url: 'https://youtu.be/Sf7taLQKpqw',
        tags: ['Weapons','Interviews'],
    },
    {
        title: 'Review: RyanSword Naginata, unboxing, initial impressions, and cutting',
        url: 'https://youtu.be/UmdfsmG1OTE',
        tags: ['Samurai','Polearms','Reviews','Cutting','Weapons'],
    },
    {
        title: 'Exciting announcement! I am an Iron Mountain Armory affiliate now!',
        url: 'https://youtu.be/GnGXLZ8u3AA',
        tags: ['Samurai','Armor'],
    },
    {
        title: 'Was Medieval Armor More Dangerous Than None??? (Responses from a historical armored combat fighter)',
        url: 'https://youtu.be/a67TKZWeqUs',
        tags: ['Knight','Armor','Video Reply','Debunking'],
    },
    {
        title: 'StoneBuiltKnives Winner (second drawing)',
        url: 'https://youtu.be/iEhdRhVF2pE',
        tags: ['Giveaways'],
    },
    {
        title: 'StoneBuiltKnives contest winner drawing (Live)',
        url: 'https://youtu.be/wiTN9p8Pdrg',
        tags: ['Giveaways'],
    },
]

export default videoList

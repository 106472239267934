import { Col } from 'react-bootstrap'

export default function About(props){
    return (
      <Col>
        <h2>Contact</h2>
        <p>
          The best way to contact me is via email:
        </p>
        <p>
          samurai.james.1603(at)gmail(dot)com
        </p>
      </Col>
    )
  }
  